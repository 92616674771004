import React from 'react'
import Modal from '../Modal'
import ProductButton from '../ProductButton'
import { useTranslations } from '../../../hooks/use-translations'
import { toLink } from '../../../utils'

import * as s from './ModalDownloadApp.module.scss'

interface ModalAppProps {
  appIosLink: string
  appAndroidLink: string
  onClose: () => void
  title: string
}

const ModalDownloadApp: React.FC<ModalAppProps> = ({
  appAndroidLink,
  appIosLink,
  onClose,
  title,
}) => {
  const { t } = useTranslations()
  return (
    <Modal onClose={onClose} title={title}>
      <div className="w-100 flex justify-between" style={{ gap: 12 }}>
        <ProductButton
          className={s.product_link}
          arrowClassName={s.product_link_arrow}
          logoClassName={s.product_link_logo}
          onClick={() => toLink(appIosLink)}
          title={t('App Store for iPhone')}
          iconType="app-store"
        />
        <ProductButton
          className={s.product_link}
          arrowClassName={s.product_link_arrow}
          logoClassName={s.product_link_logo}
          onClick={() => toLink(appAndroidLink)}
          color="green"
          title={t('Google Play for Android')}
          iconType="play-market"
        />
      </div>
    </Modal>
  )
}

export default ModalDownloadApp
