import React from 'react'
import cn from 'classnames'
import Typography from '../Typography'
import ArrowLinkButton from '../../../svg/arrow-link-button'
import SignalMiddleIcon from '../../../svg/signal-middle-icon'
import AppStore from '../../../svg/app-store'
import PlayMarketAndroid from '../../../svg/play-market-android'

import * as s from './ProductLinks.module.scss'

type IconType = 'app-store' | 'play-market' | 'signal'

interface ProductButtonProps {
  title: string
  iconType?: IconType
  color?: 'blue' | 'green'
  className?: string
  logoClassName?: string
  arrowClassName?: string
  onClick?: () => void
}

const ProductButton: React.FC<ProductButtonProps> = ({
  title,
  color = 'blue',
  iconType = 'signal',
  className,
  logoClassName,
  arrowClassName,
  onClick,
}) => {
  return (
    <div
      className={cn(
        s.product,
        s[`product_${color}`],
        onClick && 'cursor-pointer',
        className
      )}
      onClick={() => onClick?.()}
    >
      <div className="relative w-100 h-100 flex flex-col justify-center items-center">
        {renderIcon(iconType, logoClassName)}
        <Typography
          variant="body2"
          color="white"
          position="center"
          style={{ fontWeight: 500 }}
        >
          {title}
        </Typography>
        <ArrowLinkButton
          color="white"
          className={cn(s.arrow, arrowClassName)}
        />
      </div>
    </div>
  )
}

export default ProductButton

const renderIcon = (iconType: IconType, logoClassName?: string) => {
  switch (iconType) {
    case 'app-store':
      return <AppStore className={cn(s.icon, logoClassName)} />
    case 'play-market':
      return <PlayMarketAndroid className={cn(s.icon, logoClassName)} />
    case 'signal':
      return <SignalMiddleIcon className={cn(s.icon, logoClassName)} />
  }
}
