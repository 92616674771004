import React from 'react'

const PlayMarketAndroid: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      color="#0695D7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.23567 37.9063C2.64039 37.9063 1 39.2005 1 41.4658C1 43.5368 2.44228 44.9996 4.23567 44.9996C5.71602 44.9996 6.37579 43.9936 6.37579 43.9936V44.4321C6.37579 44.6394 6.56934 44.8706 6.80891 44.8706H7.87897V38.061H6.37579V38.9251C6.37579 38.9251 5.71049 37.9063 4.23567 37.9063ZM4.50344 39.2987C5.81669 39.2987 6.50557 40.468 6.50557 41.4653C6.50557 42.5762 5.68766 43.6312 4.50622 43.6312C3.51861 43.6312 2.52931 42.8214 2.52931 41.4507C2.52931 40.2133 3.38067 39.2987 4.50344 39.2987Z"
        fill="currentColor"
      />
      <path
        d="M9.96827 44.8708C9.73733 44.8708 9.53516 44.703 9.53516 44.4323V38.0611H11.0383V38.9038C11.379 38.3855 12.0444 37.9043 13.0658 37.9043C14.7353 37.9043 15.6243 39.2517 15.6243 40.5116V44.8707H14.5797C14.3058 44.8707 14.1211 44.6386 14.1211 44.4065V40.8469C14.1211 40.1485 13.6985 39.3004 12.721 39.3004C11.6664 39.3004 11.0383 40.3095 11.0383 41.2596V44.8707L9.96827 44.8708Z"
        fill="currentColor"
      />
      <path
        d="M20.0062 37.9063C18.4109 37.9063 16.7705 39.2005 16.7705 41.4659C16.7705 43.5369 18.2128 44.9997 20.0062 44.9997C21.4865 44.9997 22.1463 43.9937 22.1463 43.9937V44.4322C22.1463 44.6395 22.3398 44.8707 22.5794 44.8707H23.6495V34.6562H22.1463V38.9252C22.1463 38.9252 21.481 37.9063 20.0062 37.9063ZM20.2739 39.2987C21.5872 39.2987 22.276 40.468 22.276 41.4654C22.276 42.5763 21.4581 43.6313 20.2767 43.6313C19.2891 43.6313 18.2998 42.8215 18.2998 41.4507C18.2998 40.2134 19.1511 39.2987 20.2739 39.2987Z"
        fill="currentColor"
      />
      <path
        d="M25.7388 44.8704C25.5078 44.8704 25.3057 44.7027 25.3057 44.4319V38.0608H26.8088V39.1957C27.0676 38.5599 27.6259 37.9834 28.6178 37.9834C28.8943 37.9834 29.1528 38.035 29.1528 38.035V39.6084C29.1528 39.6084 28.83 39.4794 28.4394 39.4794C27.3847 39.4794 26.8088 40.4886 26.8088 41.4387V44.8704H25.7388Z"
        fill="currentColor"
      />
      <path
        d="M38.2735 44.8707C38.0425 44.8707 37.8403 44.7029 37.8403 44.4321V38.061H39.3435V44.8707L38.2735 44.8707Z"
        fill="currentColor"
      />
      <path
        d="M43.7513 37.9063C42.156 37.9063 40.5156 39.2005 40.5156 41.4659C40.5156 43.5369 41.9579 44.9997 43.7513 44.9997C45.2316 44.9997 45.8914 43.9937 45.8914 43.9937V44.4322C45.8914 44.6395 46.085 44.8707 46.3245 44.8707H47.3946V34.6562H45.8914V38.9252C45.8914 38.9252 45.2261 37.9063 43.7513 37.9063ZM44.0191 39.2987C45.3323 39.2987 46.0212 40.468 46.0212 41.4654C46.0212 42.5763 45.2033 43.6313 44.0218 43.6313C43.0342 43.6313 42.0449 42.8215 42.0449 41.4507C42.0449 40.2134 42.8963 39.2987 44.0191 39.2987Z"
        fill="currentColor"
      />
      <path
        d="M38.5813 36.5926C39.1302 36.5926 39.5752 36.1421 39.5752 35.5863C39.5752 35.0306 39.1302 34.5801 38.5813 34.5801C38.0324 34.5801 37.5874 35.0306 37.5874 35.5863C37.5874 36.1421 38.0324 36.5926 38.5813 36.5926Z"
        fill="currentColor"
      />
      <path
        d="M33.117 37.9038C31.4464 37.9038 29.6108 39.1664 29.6108 41.4536C29.6108 43.5378 31.1743 44.9997 33.1134 44.9997C35.5032 44.9997 36.6701 43.0547 36.6701 41.4668C36.6701 39.5182 35.1675 37.9038 33.117 37.9038ZM33.1225 39.325C34.2778 39.325 35.1396 40.2677 35.1396 41.4578C35.1396 42.6686 34.2248 43.6029 33.1259 43.6029C32.1059 43.6029 31.1143 42.7625 31.1143 41.4782C31.1143 40.1725 32.0574 39.325 33.1225 39.325Z"
        fill="currentColor"
      />
      <path
        d="M35.5196 11.9313L39.3656 5.18618C39.5826 4.80805 39.4514 4.32771 39.0829 4.10799C38.7145 3.88826 38.235 4.01601 38.023 4.39414L34.1266 11.2261C31.1537 9.85152 27.8074 9.08502 24.2088 9.08502C20.6101 9.08502 17.2638 9.85151 14.291 11.2261L10.3945 4.39414C10.1775 4.01601 9.70303 3.88826 9.32954 4.10799C8.95604 4.32771 8.82986 4.80805 9.04689 5.18618L12.8929 11.9313C6.26082 15.5695 1.76878 22.3657 1.02686 30.3218H47.3907C46.6488 22.3656 42.1567 15.5694 35.5196 11.9313ZM13.5642 23.73C12.4891 23.73 11.621 22.846 11.621 21.7627C11.621 20.6743 12.4942 19.7954 13.5642 19.7954C14.6392 19.7954 15.5073 20.6794 15.5073 21.7627C15.5124 22.846 14.6392 23.73 13.5642 23.73ZM34.8484 23.73C33.7733 23.73 32.9052 22.846 32.9052 21.7627C32.9052 20.6743 33.7784 19.7954 34.8484 19.7954C35.9234 19.7954 36.7915 20.6794 36.7915 21.7627C36.7966 22.846 35.9234 23.73 34.8484 23.73Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlayMarketAndroid
