import React from 'react'
import cn from 'classnames'

import Typography from '../Common/Typography'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import dashVideoEn from '../../assets/video/dashboard-en.mp4'
import dashVideoRu from '../../assets/video/dashboard-ru.mp4'

import * as s from './DashboardModuleBlock.module.scss'

interface DashboardModuleBlockProps {
  reverseElements?: boolean
}

const DashboardModuleBlock: React.FC<DashboardModuleBlockProps> = ({
  reverseElements = false,
}) => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  return (
    <div className={cn(s.content, reverseElements && s.reverse)}>
      <div className={s.column}>
        <Typography variant="h2" size={40} color="blue" mb={16}>
          + {t('DASHBOARD module')}
        </Typography>
        <Typography variant="body1" size={18}>
          {t('DASHBOARD module text')}
        </Typography>
      </div>
      <div className={s.column}>
        <video autoPlay muted loop playsInline title={t('DASHBOARD module')}>
          <source
            src={region === AppRegionEnum.Ru ? dashVideoRu : dashVideoEn}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  )
}

export default DashboardModuleBlock
