import React, { useRef, useState } from 'react'

const VideoSection: React.FC<{ videoId: string }> = ({ videoId }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [loading, setLoading] = useState(false)

  const handleFieldView = (intersection: IntersectionObserverEntryInit[]) => {
    const [IntersectionEntry] = intersection
    if (IntersectionEntry.intersectionRatio) {
      setLoading(true)
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleFieldView, {
      threshold: 0,
      rootMargin: '20%',
    })
    if (iframeRef.current) {
      observer.observe(iframeRef.current)
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current)
      }
    }
  }, [])

  return (
    <section className="relative px-0 py-0 has-background-light">
      <div className="auto-height-box mx-auto is-clipped max-h-screen max-w-screen-2xl max-h-view">
        <div className="auto-height-box__item">
          {renderVideoViewer(videoId, iframeRef, loading)}
        </div>
      </div>
    </section>
  )
}

function getIframeSrc(videoId: string) {
  return `https://www.youtube.com/embed/${videoId}?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=0&amp;disablekb=1&amp;modestbranding=1`
}
function renderVideoViewer(
  youtubeId: string,
  ref: React.RefObject<HTMLIFrameElement>,
  loading: boolean
) {
  return (
    <iframe
      key={youtubeId}
      ref={ref}
      title="Signal promo video"
      className="w-100 h-100 is-shadowless"
      src={loading ? getIframeSrc(youtubeId) : ''}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}

export default VideoSection
